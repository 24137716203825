import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";

const NewsPage = () => (
  <Layout>
    <Seo title="News—January 2012" />
	<NewsHeader />
    <h2>January 2012</h2>




	<h3 className="blogdate">
	Sunday, January 15, 2012
	</h3>

	<h4 className="blogitemtitle">
	Still travelling... and a special giveaway
	</h4>
	   <div className="blogitembody">
	   <p>
	Wow, I can't believe it's been nearly a year since my last update; since
	I released <i>Everyelsewhere EP</i>! It's true that time flies when
	you're having fun. Or, you could say time flies when you're living. Or
	time flies. Well, I'm trying to pay no attention to time.
	</p>
	<p>
	Before I go further, I would like to announce that, effective now, if
	you buy <i>Everyelsewhere EP</i> from my Bandcamp store, I will email
	you a free download code for <i>Seventeen Oh-Two Oh-Six</i>! That's
	right - until further notice - you get a free high quality digital
	download of <i>Seventeen Oh-Two Oh-Six</i> when you purchase <i>Everyelsewhere
	EP</i>. Just make sure you leave a good email address so i can email you
	the download code. Tell a friend!
	</p>
	<ul>
	<li>
		<a href="http://store.jesserivest.com/album/everyelsewhere-ep">bandcamp store</a>
	</li>
	<li>
		<Link to="/music/02">Everyelsewhere EP music page</Link>
	</li>
	</ul>
	<p>
	If you've been following on Facebook, you may remember some of the
	events of my year; from hitchhiking the North Island of New Zealand in
	February; to Buenos Aires in May; to Canada, friends and family from
	June to September; and then back to Buenos Aires. I'm still in Buenos
	Aires!
	</p>
	<p>
	I have a couple favourite places here in Buenos Aires where I go to work
	on some new songs; and it's a bit tricky but I am also looking for
	performance and collaboration opportunities while I'm here. I did play
	several short notice shows in Canada; most exciting for me was one in
	Montreal on my first ever visit - a cool city!
	</p>
	<p>
	Well, I'll keep this short, however I do invite you to join me on
	Facebook or Twitter. Until next time, rock on!
	</p>
	<p>
	Jesse
	</p>
	   </div>
	   	   <div className="blogitemfooter">
	   <p>posted by Jesse @ 06:49 +1300</p>
	   </div>





	<p>
		<Link to="/news/">Go back to the news index page</Link>
	</p>
  </Layout>
);

export default NewsPage;
